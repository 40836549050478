export default function QueryToURLParams(query = {}) {
  const params = new URLSearchParams()

  // Handle location_id separately
  if (query.location_id !== undefined && query.location_id !== null) {
    params.append('location_id', query.location_id)
  }

  Object.keys(query).forEach((key) => {
    const value = query[key]
    if (key === 'filter_groups' && Array.isArray(value) && value.length > 0) {
      value.forEach((group, groupIndex) => {
        // Add categories
        group.categories.forEach((category) => {
          params.append(`filter_groups[${groupIndex}][categories][]`, category)
        })

        // Add subcategories and tags
        if (group.sub_categories && group.sub_categories.length > 0) {
          group.sub_categories.forEach((subCatObj) => {
            params.append(`filter_groups[${groupIndex}][sub_categories][]`, subCatObj.sub_category)

            // Add tags for this subcategory
            if (subCatObj.tags && subCatObj.tags.length > 0) {
              subCatObj.tags.forEach((tag) => {
                params.append(`filter_groups[${groupIndex}][tags][]`, tag)
              })
            }
          })
        }
      })
    } else if (key !== 'location_id') {
      // Skip location_id as it's already handled
      if (Array.isArray(value)) {
        if (key === 'specials' && value[0] === 'online-only') {
          value.forEach((item) => {
            params.append(`tags[]`, item)
          })
        } else {
          value.forEach((item) => params.append(`${key}[]`, item))
        }
      } else if (typeof value === 'object' && value !== null) {
        params.append(`min_${key}`, value.min)
        params.append(`max_${key}`, value.max)
      } else if (typeof value === 'boolean') {
        params.append(key, value ? '1' : '0')
      } else if (value !== '' && value !== undefined && value !== null) {
        params.append(key, value)
      }
    }
  })

  return params.toString()
}
