import { defineNuxtPlugin } from '#app'
import { createGtm } from '@gtm-support/vue-gtm'

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.use(
    createGtm({
      id: 'GTM-NM3Q7B7', // Replace with your GTM container ID
      queryParams: {},
      defer: false, // Load script async by default
      compatibility: false, // Add async & defer for older browsers
      nonce: '2726c7f26c', // Add nonce attribute to GTM script
      enabled: true, // Toggle GTM dynamically (useful for GDPR compliance)
      debug: process.env.NODE_ENV === 'development', // Debug mode in dev environment
      loadScript: true, // Loads GTM script automatically
      vueRouter: nuxtApp.$router, // Sync GTM with Vue Router
      ignoredViews: [], // Don't trigger GTM on specific routes
      trackOnNextTick: false // Track view in next tick
    })
  )
})
