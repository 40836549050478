export default defineNuxtPlugin(() => {
  if (process.client) {
    const s = document.createElement('script')
    s.src = 'https://acsbapp.com/apps/app/dist/js/app.js'
    s.async = true
    s.onload = function () {
      if (window.acsbJS) {
        window.acsbJS.init()
      }
    }
    document.body.appendChild(s)
  }
})
